import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    '@keyframes slidein-left': {
        '0%': {
            transform: 'translateX(-7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    '@keyframes slidein-right': {
        '0%': {
            transform: 'translateX(7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    singleChartWrapper: {
        minHeight: '19.07vh',
        maxHeight: '19.07vh',
        minWidth: '5.36vw',
        maxWidth: '5.36vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: '1.4vw',
        '@media (max-width: 1366px)': {
            minWidth: '8.36vw',
            maxWidth: '8.36vw',
            marginRight: 0,
        },
        '@media (max-width: 768px)': {
            minWidth: '17.36vw',
            maxWidth: '17.36vw',
            marginRight: 0,
        },
    },
    singleChartColumnsWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        maxHeight: '15.57vh',
        minHeight: '15.57vh',
        position: 'relative',
    },
    singleChartColumnWrapper: {
        margin: '0 3px',
        textAlign: 'center',
        maxHeight: '15.57vh',
        minHeight: '15.57vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    singleChartColumnNumber: {
        fontSize: 12,
        margin: '0 auto',
        '@media (max-width: 768px)': {
            fontSize: '12px',
        },
        '@media (max-width: 460px)': {
            fontSize: '8px',
        },
    },
    singleChartColumnOne: {
        maxWidth: '3.5vw',
        maxHeight: '13.07vh',
        minWidth: '3.5vw',
        position: 'absolute',
        bottom: 0,
        left: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: props => `${props.columnOneHeight}vh`,
        backgroundColor: '#FA7800',
        '@media (max-width: 1024px)': {
            minWidth: '5.36vw',
            maxWidth: '5.36vw',
        },
        '@media (max-width: 768px)': {
            minWidth: '9.36vw',
            maxWidth: '9.36vw',
        },
        '@media (max-width: 460px)': {
            minWidth: '11.36vw',
            maxWidth: '11.36vw',
        },
    },
    singleChartColumnTwo: {
        maxHeight: '13.07vh',
        height: props => `${props.columnTwoHeight}vh`,
        minWidth: '3.5vw',
        maxWidth: '3.5vw',
        backgroundColor: 'rgba(255,255,255, 0.9)',

        '@media (max-width: 1024px)': {
            minWidth: '5.36vw',
            maxWidth: '5.36vw',
        },
        '@media (max-width: 768px)': {
            minWidth: '9.36vw',
            maxWidth: '9.36vw',
        },
        '@media (max-width: 460px)': {
            minWidth: '11.36vw',
            maxWidth: '11.36vw',
        },
    },
    yearAndPercentWrapper: {
        marginTop: '0.74vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    year: {
        fontSize: '1.48vh',
        lineHeight: '1.48vh',
    },
    percentage: {
        color: 'white',
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '1px',
        lineHeight: '1.9vh',
    },
    icon: {
        height: '100vh',

        padding: theme.spacing(16, 16),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(10, 14),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
            backgroundSize: 'auto 0vh',
            animation: 'none',
            height: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(10, 5),
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    girdContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
    },
    text: {
        fontSize: '32px',
        lineHeight: '38px',
        letterSpacing: '0.01em',
        fontWeight: 'bold',
        [theme.breakpoints.down('lg')]: {
            fontSize: '26px',
            lineHeight: '30px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '21px',
            lineHeight: '24px',
        },
    },
    titleBox: {
        maxWidth: '320px',
        margin: '10vh 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // [theme.breakpoints.down('lg')]: {
        //     maxWidth: '95%',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     animation: 'none',
        //     maxWidth: '100%',
        // },
        animation: '$slidein-left 0.5s ease backwards',
    },
    description: {
        maxWidth: '50%',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '80%',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '85%',
        },
        [theme.breakpoints.down('sm')]: {
            animation: 'none',
            maxWidth: '100%',
        },
        animation: '$slidein-right 0.5s ease backwards',
    },
    hidden: {
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
        },
    },
    titleBoxHidden: {
        maxWidth: '320px',
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
        },
        margin: '10vh',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '95%',
        },
        [theme.breakpoints.down('sm')]: {
            animation: 'none',
            maxWidth: '100%',
        },
    },
    legend: {
        display: 'flex',
    },
    legendElement: {
        paddingLeft: '3.2vw',
    },
}));

import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'nuka-carousel';
import { navigate } from '@reach/router';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from '../styles/recommendations.styles';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { arms } from '../utils/armsList';
import StyledIcon from '../components/StyledIcon';
import { handleObserver } from '../utils/observer';
import {
    recomendationsHeaderEN,
    recomendationsHeaderPL,
    recomendationsTextEN,
    recomendationsTextPL,
} from '../utils/static';

const Recommendations = ({ isEnglish }) => {
    const classes = useStyles();
    const [isVisible, setVisible] = useState(false);
    const [buttonBlock, setButtonBlock] = useState(false);

    const refRecommendations = useRef(null);
    const textWithHardSpace = isEnglish
        ? recomendationsTextEN
        : recomendationsTextPL;

    useEffect(() => {
        const currentRef = refRecommendations.current;
        handleObserver(currentRef, setVisible);
    }, []);
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Layout
            footerTitle={isEnglish ? 'Contact' : 'Kontakt'}
            href="#kontakt"
            backgroundColor="black"
        >
            <SEO title="Współpraca" />
            <Box className={classes.gif} id="wspolpraca">
                <div ref={refRecommendations}>
                    <Box className={classes.root}>
                        <Box
                            width="100%"
                            className={
                                isVisible
                                    ? classes.gridContainer
                                    : classes.hidden
                            }
                        >
                            <Typography variant="h1">
                                {isEnglish
                                    ? recomendationsHeaderEN
                                    : recomendationsHeaderPL}
                            </Typography>
                        </Box>
                        <Box>
                            <Carousel
                                className={classes.carouselWrapper}
                                dragging={false}
                                swiping={false}
                                slidesToShow={xsDown ? 1 : smDown ? 3 : 6}
                                autoplay={true}
                                autoplayInterval={2000}
                                wrapAround={true}
                                slidesToScroll={xsDown ? 1 : smDown ? 2 : 4}
                                speed={300}
                                framePadding="0px 48px"
                                cellSpacing={6}
                                renderCenterLeftControls={({
                                    previousSlide,
                                }) => (
                                    <Box
                                        className={classes.prev}
                                        onClick={() => {
                                            if (!buttonBlock) {
                                                setButtonBlock(true);
                                                previousSlide();
                                                setTimeout(() => {setButtonBlock(false);}, 450);
                                            }
                                        }}
                                    />
                                )}
                                renderCenterRightControls={({ nextSlide }) => (
                                    <Box
                                        className={classes.next}
                                        onClick={() => {
                                            if (!buttonBlock) {
                                                setButtonBlock(true);
                                                nextSlide();
                                                setTimeout(() => {setButtonBlock(false);}, 450);
                                            }
                                        }}
                                    />
                                )}
                                renderBottomCenterControls={() => false}
                            >
                                {arms.map(item => (
                                    <Box
                                        key={item.title}
                                        className={classes.slider}
                                    >
                                        <StyledIcon
                                            imageGray={item.imageGray}
                                            imageColor={item.imageColor}
                                        />
                                        <Box width="100%">
                                            <Typography
                                                className={classes.text}
                                            >
                                                {item.title}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Carousel>
                        </Box>
                        <Box
                            className={
                                isVisible
                                    ? classes.gridContainer
                                    : classes.hidden
                            }
                            mt={8}
                        >
                            <Typography variant="body2">
                                {textWithHardSpace}
                                <MuiLink
                                    onClick={() => navigate('#kontakt')}
                                    className={classes.link}
                                    underline="none"
                                >
                                    {isEnglish
                                        ? 'contact us.'
                                        : 'skontaktuj się z nami.'}
                                </MuiLink>
                            </Typography>
                        </Box>
                    </Box>
                </div>
            </Box>
        </Layout>
    );
};
export default Recommendations;

import React from 'react';
import PropTypes from 'prop-types';

import MuiLink from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';

import { useStyles } from '../styles/header.styles';
import { navListPL, navListEN } from '../utils/navList';
import NavItem from './NavItem';
import logoCivileo from '../images/icons/logoCivileo.svg';
import iconCivileo from '../images/icons/iconCivileo.svg';
import iconCivileoBlack from '../images/icons/iconCivileoBlack.svg';
import mobileMenuButton from '../images/icons/mobileMenuButton.svg';
import closeButton from '../images/icons/close.svg';
import { languageShortCuts } from '../utils/static';

const Header = ({
    shouldChangeColor,
    shouldResize,
    drawerOn,
    setDrawerOn,
    isEnglish,
    setIsEnglish,
}) => {
    const classes = useStyles({ drawerOn });
    const handleLanguageChange = () => {
        isEnglish == 0 ? setIsEnglish(1) : setIsEnglish(0);
    };
    const navList = isEnglish ? navListEN : navListPL;
    return (
        <AppBar
            className={
                shouldResize
                    ? `${classes.appbar}, ${classes.appbarBlack}`
                    : classes.appbar
            }
            elevation={0}
        >
            <Hidden only={['sm', 'xs']}>
                <Grid container justify="space-between">
                    <Grid
                        item
                        md={3}
                        lg={4}
                        xl={5}
                        className={classes.gridContainer}
                    >
                        <div
                            className={classes.languageSwitch}
                            onClick={handleLanguageChange}
                        >
                            <p>{languageShortCuts[isEnglish]}</p>
                        </div>

                        <MuiLink href="#">
                            {shouldResize && shouldChangeColor ? (
                                <img
                                    src={iconCivileoBlack}
                                    alt="Civileo icon"
                                    className={classes.iconCivileo}
                                />
                            ) : shouldResize ? (
                                <img
                                    src={iconCivileo}
                                    alt="Civileo icon"
                                    className={classes.iconCivileo}
                                />
                            ) : (
                                <img
                                    src={logoCivileo}
                                    alt="Civileo icon"
                                    className={classes.civileo}
                                />
                            )}
                        </MuiLink>
                    </Grid>
                    <Grid
                        item
                        container
                        md={8}
                        lg={8}
                        xl={7}
                        justify="space-between"
                    >
                        {navList.map(item => (
                            <Grid key={`${item.title}`} item xs>
                                <NavItem
                                    item={item}
                                    shouldResize={shouldResize}
                                    shouldChangeColor={shouldChangeColor}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden only={['xl', 'lg', 'md']}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs>
                        <MuiLink href="#">
                            <img
                                src={iconCivileo}
                                alt="Civileo icon"
                                className={classes.iconCivileo}
                            />
                        </MuiLink>
                    </Grid>

                    <Grid item sm className={classes.menuButton}>
                        <div
                            className={classes.languageSwitch}
                            onClick={handleLanguageChange}
                        >
                            <p>{languageShortCuts[isEnglish]}</p>
                        </div>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setDrawerOn(true);
                            }}
                        >
                            <img src={mobileMenuButton} alt="Mobile button" />
                        </IconButton>
                    </Grid>

                    <Drawer
                        open={drawerOn}
                        anchor="top"
                        classes={{ paper: classes.drawer }}
                        onClose={() => {
                            setDrawerOn(false);
                        }}
                    >
                        <Grid item container direction="column">
                            <Grid item className={classes.item}>
                                <MuiLink href="#">
                                    <img
                                        src={logoCivileo}
                                        className={classes.civileo}
                                        alt="Civileo icon"
                                    />
                                </MuiLink>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setDrawerOn(false);
                                    }}
                                >
                                    <img src={closeButton} alt="Close button" />
                                </IconButton>
                            </Grid>
                            {navList.map(item => (
                                <Grid key={`${item.title}`} item xs>
                                    <NavItem
                                        item={item}
                                        shouldResize={shouldResize}
                                        setDrawerOn={setDrawerOn}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Drawer>
                </Grid>
            </Hidden>
        </AppBar>
    );
};

Header.propTypes = {
    shouldResize: PropTypes.bool.isRequired,
    shouldChangeColor: PropTypes.bool.isRequired,
    drawerOn: PropTypes.bool.isRequired,
    setDrawerOn: PropTypes.func.isRequired,
};

export default Header;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import loadingGif from '../images/loadingGif.gif';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundImage: `url(${loadingGif})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        height: props => props.oneVh * 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: theme.spacing(4),
    },
}));

const Loading = ({ progress }) => {
    const [oneVh, setOneVh] = useState(0);

    const classes = useStyles({ oneVh });

    const handleSetHeight = () => {
        const vh = (typeof window !== 'undefined' && window.innerHeight) / 100;
        setOneVh(vh);
    };
    useEffect(() => {
        handleSetHeight();
    }, []);
    return (
        <Box className={classes.root}>
            <Typography align="center" paragraph variant="body2" gutterBottom>
                {progress}% Loading...
            </Typography>
        </Box>
    );
};
export default Loading;

Loading.propTypes = {
    progress: PropTypes.number.isRequired,
};

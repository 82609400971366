export const navListPL = [
    {
        href: '#civileo',
        title: 'CIVILEO',
        color: '#140091',
        backgroundColor: '#FFF',
    },
    {
        href: '#news',
        title: 'AKTUALNOŚCI',
        color: '#140091',
        backgroundColor: '#FFF',
    },
    {
        href: '#vileo',
        title: 'VILEO',
        color: '#FFF',
        backgroundColor: '#0072FF',
    },
    {
        href: '#smart-city',
        title: 'SMART CITY',
        color: '#FFF',
        backgroundColor: '#033BF6',
    },
    {
        href: '#wspolpraca',
        title: 'WSPÓŁPRACA',
        color: '#FFF',
        backgroundColor: '#140091',
    },
    {
        href: '#kontakt',
        title: 'KONTAKT',
        color: '#FFF',
        backgroundColor: '#FA7800',
    },
];

export const navListEN = [
    {
        href: '#civileo',
        title: 'CIVILEO',
        color: '#140091',
        backgroundColor: '#FFF',
    },
    {
        href: '#news',
        title: 'NEWS',
        color: '#140091',
        backgroundColor: '#FFF',
    },
    {
        href: '#vileo',
        title: 'VILEO',
        color: '#FFF',
        backgroundColor: '#0072FF',
    },
    {
        href: '#smart-city',
        title: 'SMART CITY',
        color: '#FFF',
        backgroundColor: '#033BF6',
    },
    {
        href: '#wspolpraca',
        title: 'COOPERATION',
        color: '#FFF',
        backgroundColor: '#140091',
    },
    {
        href: '#kontakt',
        title: 'CONTACT',
        color: '#FFF',
        backgroundColor: '#FA7800',
    },
];

import React, { useRef, useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Carousel from 'nuka-carousel';

import { useStylesNews } from '../styles/news.styles';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { handleObserver } from '../utils/observer';

import { graphql, useStaticQuery } from 'gatsby';


const News = ({ isEnglish }) => {
    const data = useStaticQuery(query);

    const classes = useStylesNews();

    const refNews = useRef(null);
    const [isVisible, setVisible] = useState(false);
    const [datacms, setDatacms] = useState(undefined);
    const [buttonBlock, setButtonBlock] = useState(false);

    useEffect(() => {
        const currentRef = refNews.current;
        handleObserver(currentRef, setVisible);
    }, []);

    useEffect(() => {
        if (data) {
            setDatacms(data)
        }
    }, [data]);

    return (
        <Layout>
            <SEO title="News" />

            <Box className={classes.root} id="news">
                <div ref={refNews}>
                    <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                        spacing={2}
                        className={classes.container}
                    >   
                    <Carousel
                        cellAlign={'center'}
                        dragging={false}
                        swiping={true}
                        wrapAround={true}
                        speed={300}
                        framePadding="0px"
                        withoutControls={datacms ? datacms.allDatoCmsNews.nodes.length === 1: true}

                        renderCenterLeftControls={({
                            previousSlide,
                        }) => (
                            <Box
                                className={classes.prev}
                                onClick={() => {
                                    if (!buttonBlock) {
                                        setButtonBlock(true);
                                        previousSlide();
                                        setTimeout(() => {setButtonBlock(false);}, 450);
                                    }
                                }}
                            />
                        )}
                        renderCenterRightControls={({ nextSlide }) => (
                            <Box
                                className={classes.next}
                                onClick={() => {
                                    if (!buttonBlock) {
                                        setButtonBlock(true);
                                        nextSlide();
                                        setTimeout(() => {setButtonBlock(false);}, 450);
                                    }
                                }}
                            />
                        )}
                        renderTopCenterControls={({ currentSlide }) => (
                            <Box
                                className={classes.pagingDotsCSS}
                                pagingDots={currentSlide}
                            />
                        )}
                    > 
                    {datacms && datacms.allDatoCmsNews.nodes.map(({title, newscontent, newscontenten, image, imageen }) => {
                        return (
                            <div key={title} className={classes.contentWrapper}>
                                <Grid item xs>
                                    <Box className={classes.box}>
                                        <Typography
                                            className={
                                                isVisible
                                                    ? classes.text
                                                    : classes.hiddenText
                                            }
                                            gutterBottom
                                        >
                                            <p>{isEnglish ? newscontenten : newscontent}</p>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs className={classes.imgContainer}>
                                    <img
                                        src={isEnglish ? imageen == null ? image.url : imageen.url : image.url}
                                        alt="News"
                                        className={
                                            isVisible
                                                ? classes.img
                                                : classes.hiddenImage
                                        }
                                    />
                                </Grid>
                            </div>
                            )
                    })}
                    </Carousel>
                    </Grid>
                </div>
            </Box>
        </Layout>
    );
};

export const query = graphql`
  {
    allDatoCmsNews {
        nodes {
          title
          newscontent
          newscontenten
          image {
            url
          }
          imageen {
            url
          }
        }
    }
  }
`

export default News;

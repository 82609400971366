import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    item: {
        height: '6.3vh',
        padding: props =>
            props.shouldResize ? theme.spacing(4, 0) : theme.spacing(6, 0),
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '&:hover': {
            backgroundColor: props =>
                props.active ? 'transparent' : props.backgroundColor,
            cursor: 'pointer',
            fontWeight: 900,
            color: props =>
                props.shouldChangeColor && props.active
                    ? theme.palette.common.black
                    : theme.palette.common.white,
        },
        webkitTransition: 'all 0.2s ease-in-out',
        transition: 'all 0.2s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            borderBottom: '1px solid rgba(255,255,255, 0.7)',
            padding: props => theme.spacing(2, 4),
            textAlign: 'left',
            justifyContent: 'left',
        },
        [theme.breakpoints.down('xs')]: {
            height: '8vh',
        },
        color: props =>
            props.shouldChangeColor
                ? theme.palette.common.black
                : theme.palette.common.white,
        fontSize: '18px',
        fontWeight: props => (props.active ? 900 : 600),
        lineHeight: '21px',
        letterSpacing: '0.1em',
        [theme.breakpoints.down('lg')]: {
            whiteSpace: 'pre-line',
            fontSize: '18px',
            lineHeight: '22px',
            height: '8vh',
        },
        [theme.breakpoints.down('md')]: {
            whiteSpace: 'pre-line',
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    civileo: {
        height: '6.3vh',
        padding: props =>
            props.shouldResize ? theme.spacing(4, 0) : theme.spacing(6, 0),
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: props =>
                props.active
                    ? 'transparent'
                    : props.shouldChangeColor
                    ? theme.palette.common.black
                    : props.backgroundColor,
            cursor: 'pointer',
            color: props =>
                props.active
                    ? theme.palette.common.white
                    : props.shouldChangeColor
                    ? theme.palette.common.white
                    : theme.palette.darkBlue,
            fontWeight: 900,
        },
        webkitTransition: 'all 0.2s ease-in-out',
        transition: 'all 0.2s ease-in-out',
        [theme.breakpoints.down('sm')]: {
            borderBottom: '1px solid rgba(255,255,255, 0.7)',
            padding: props => theme.spacing(2, 4),
            textAlign: 'left',
            justifyContent: 'left',
        },
        [theme.breakpoints.down('xs')]: {
            height: '8vh',
        },
        color: props =>
            props.shouldChangeColor
                ? theme.palette.common.black
                : theme.palette.common.white,
        fontSize: '18px',
        fontWeight: props => (props.active ? 900 : 600),
        lineHeight: '21px',
        letterSpacing: '0.1em',
        [theme.breakpoints.down('lg')]: {
            whiteSpace: 'pre-line',
            fontSize: '18px',
            lineHeight: '22px',
            height: '8vh',
        },
        [theme.breakpoints.down('md')]: {
            whiteSpace: 'pre-line',
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
}));

import React, { useState, useEffect, useRef, useCallback } from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import { navigate } from '@reach/router';

import { useStyles } from '../styles/index.styles';
import Loading from '../components/Loading';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import System from './system';
import Vileo from './vileo';
import Recommendations from './recommendations';
import SmartCity from './smart-city';
import SmartCityCharts from './smart-city-charts';
import Contact from './contact';
import logoFE from '../images/icons/logoFE.svg';
import logoFEEN from '../images/icons/logoFE-EN.svg';
import logoUE from '../images/icons/logoUE.svg';
import logoUEEN from '../images/icons/logoUE-EN.svg';
import Header from '../components/Header';
import logoOKE from '../images/icons/logoOKE.svg';
import logoPL from '../images/icons/logoPL.svg';
import logoPLEN from '../images/icons/logoPL-EN.svg';
import logoBR from '../images/icons/logoBR.svg';
import logoFEMobile from '../images/funds.svg';
import logoUEMobile from '../images/euflag.svg';
import logoPLMobile from '../images/plflag.svg';
import { handleObserver } from '../utils/observer';
import { indexHeaderPL, indexHeaderEN } from '../utils/static';
import News from './news';

const Index = () => {
    const hash = typeof window !== 'undefined' && window.location.href;
    const classes = useStyles();
    const [isLoading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [shouldResize, setResize] = useState(false);
    const [isEnglish, setIsEnglish] = useState(0);
    const [shouldChangeColor, setChangeColor] = useState(false);
    const vileo = useRef(null);
    const [isVisible, setVisible] = useState(false);
    const refIndex = useRef(null);
    const [drawerOn, setDrawerOn] = useState(false);
    const { firstLinePL, secondLinePL, thirdLinePL } = indexHeaderPL;
    const { firstLineEN, secondLineEN, thirdLineEN } = indexHeaderEN;

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(oldProgress =>
                oldProgress >= 104 ? 104 : oldProgress + 4,
            );
        }, 80);
        if (progress === 104) {
            setLoading(false);
            hash && navigate(hash);
        }
        return () => clearTimeout(timer);
    }, [progress]);

    const handleScroll = useCallback(() => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 1 && !shouldResize) {
            setResize(true);
        } else {
            setResize(false);
        }
    }, [shouldResize]);

    useEffect(() => {
        const currentRef = refIndex.current;
        window.addEventListener('scroll', handleScroll);
        handleObserver(currentRef, setVisible);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <>
            {isLoading ? (
                <Loading progress={progress} />
            ) : (
                <>
                    <Header
                        shouldResize={shouldResize}
                        drawerOn={drawerOn}
                        setDrawerOn={setDrawerOn}
                        shouldChangeColor={shouldChangeColor}
                        isEnglish={isEnglish}
                        setIsEnglish={setIsEnglish}
                    />
                    <Layout
                        footerTitle="News" 
                        href="#news" 
                        backgroundColor="#033BF6"
                    >
                        <SEO title="Home" />
                        <Box className={classes.root} maxWidth="xl">
                            <div
                                ref={refIndex}
                                className={
                                    drawerOn
                                        ? classes.contentShift
                                        : classes.headerText
                                }
                            >
                                <Typography className={classes.text}>
                                    {isEnglish ? firstLineEN : firstLinePL}
                                    <br />
                                    {isEnglish ? secondLineEN : secondLinePL}
                                    <br />
                                    {isEnglish ? thirdLineEN : thirdLinePL}
                                </Typography>

                                <Hidden only={['sm', 'xs']}>
                                    <Grid
                                        container
                                        className={classes.gridContainer}
                                    >
                                        <Grid
                                            item
                                            xs
                                            className={classes.logoContainer}
                                        >
                                            <img
                                                src={
                                                    isEnglish
                                                        ? logoFEEN
                                                        : logoFE
                                                }
                                                alt="Logo FE"
                                                className={classes.logoFR}
                                            />
                                            <img
                                                src={
                                                    isEnglish
                                                        ? logoPLEN
                                                        : logoPL
                                                }
                                                alt="Logo PL"
                                                className={classes.logo}
                                            />
                                            <img
                                                src={logoBR}
                                                alt="Logo BR"
                                                className={classes.logo}
                                            />
                                            <img
                                                src={
                                                    isEnglish
                                                        ? logoUEEN
                                                        : logoUE
                                                }
                                                alt="Logo UE"
                                                className={classes.logo}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            className={classes.logoOkeContainer}
                                        >
                                            <Link href="https://www.oke.pl">
                                                <img
                                                    src={logoOKE}
                                                    alt="Logo OKE"
                                                />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Hidden>
                                <Hidden only={['md', 'lg', 'xl']}>
                                    <Link
                                        href="https://www.oke.pl"
                                        className={classes.link}
                                    >
                                        <img
                                            src={logoOKE}
                                            alt="Logo OKE"
                                            className={classes.logoOke}
                                        />
                                    </Link>

                                    <Box className={classes.logoBox}>
                                        <img
                                            src={logoPLMobile}
                                            alt="Logo PL"
                                            className={classes.logo}
                                        />
                                        <img
                                            src={logoFEMobile}
                                            alt="Logo FE"
                                            className={classes.logo}
                                        />
                                    </Box>

                                    <Box className={classes.logoBox}>
                                        <img
                                            src={logoUEMobile}
                                            alt="Logo UE"
                                            className={classes.logo}
                                        />
                                        <img
                                            src={logoBR}
                                            alt="LogoBR"
                                            className={classes.logo}
                                        />
                                    </Box>
                                </Hidden>
                            </div>
                        </Box>
                    </Layout>
                    <News isEnglish={isEnglish} />
                    <System isEnglish={isEnglish} />
                    <div ref={vileo}>
                        <Vileo isEnglish={isEnglish} />
                    </div>
                    <SmartCity isEnglish={isEnglish} />
                    <SmartCityCharts isEnglish={isEnglish} />
                    <Recommendations isEnglish={isEnglish} />
                    <Contact isEnglish={isEnglish} />
                </>
            )}
        </>
    );
};
export default Index;

import { makeStyles } from '@material-ui/core/styles';

import backgroundGif from '../images/backgroundGif.gif';
import background from '../images/backgroundIndex.png';

export const useStyles = makeStyles(theme => ({
    '@keyframes slidein-top': {
        '0%': {
            transform: 'translateY(-13%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateY(0)',
            opacity: 1,
        },
    },
    root: {
        background: `url(${background}), url(${backgroundGif})`,
        backgroundColor: 'black',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100vw 110vh',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(16, 16),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(10, 14),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(10),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(5, 5, 0),
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.common.black,
            background: 'none',
            height: 'auto',
            paddingBottom: theme.spacing(5),
            paddingTop: '15vh'
        },
    },
    content: {
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginTop: '16vh',
        },
    },
    contentShift: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginTop: '56vh',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    gridContainer: {
        bottom: 40,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(2, 16),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(2, 14),
        },
        '@media (max-width: 1200px)': {
            padding: theme.spacing(2, 10),
            flexDirection: 'column',
            alignItems: 'stretch',
        },
    },
    logoOkeContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '20px',

        '@media (max-width: 1200px)': {
            justifyContent: 'center',
            paddingTop: theme.spacing(3),
        },
    },
    text: {
        fontSize: '55px',
        lineHeight: '65px',
        letterSpacing: '0.01em',
        fontWeight: 600,
        opacity: 1,
        color: theme.palette.common.white,
        animation: '1s $slidein-top 0.3s ease backwards',
        '@media (max-width: 1400px)': {
            fontSize: '48px',
            lineHeight: '52px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '40px',
            lineHeight: '50px',
            animation: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '19px',
            lineHeight: '23px',
            paddingTop: '6vh',
        },
        '@media (max-width: 360px)': {
            fontSize: '18px',
            lineHeight: '21px',
        },
    },
    textDesc: {
        fontSize: '16px',
        marginTop: '20px',
        animation: '1s $slidein-top 0.3s ease backwards',
    },
    headerText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '@media (min-width: 1160px)': {
            maxWidth: '50%',
        },
    },
    hidden: {
        opacity: 0,
        letterSpacing: '0.01em',
        fontWeight: 600,
        transition: 'opacity 1s, transform 1.3s',
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '40px',
            lineHeight: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '19px',
            lineHeight: '23px',
        },
        '@media (max-width: 360px)': {
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    logoFR: {
        height: '46px',
        marginRight: '10px',

        '@media (max-width: 1400px)': {
            height: '5vh',
        },
        '@media (max-width: 480px)': {
            height: '4vh',
        },
    },
    logo: {
        height: '36px',
        marginLeft: '40px',

        '@media (max-width: 1400px)': {
            height: '5vh',
            margin: 0,
        },
        '@media (max-width: 480px)': {
            height: '4vh',
        },
    },
    logoBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(0, 0, 3),
        height: '8vh',
        [theme.breakpoints.down('xs')]: {
            height: '5vh',
        },
    },
    link: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '16vh',
    },
    logoOke: {
        width: '60%',
        height: '8vh',
        [theme.breakpoints.down('xs')]: {
            height: '5vh',
            width: '80%',
        },
    },
}));

import React, { useRef, useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { useStyles } from '../styles/vileo.styles';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { handleObserver } from '../utils/observer';
import mobileApp from '../images/vileoAppGif.gif';
import { vileoAboutTextEN, vileoAboutTextPL } from '../utils/static';

const Vileo = ({ isEnglish }) => {
    const classes = useStyles();

    const refVileo = useRef(null);
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        const currentRef = refVileo.current;
        handleObserver(currentRef, setVisible);
    }, []);

    const vileoAboutText = isEnglish ? vileoAboutTextEN : vileoAboutTextPL;

    return (
        <Layout 
            footerTitle="Smart City"
            href="#smart-city"
            backgroundColor="#033BF6"
        >
            <SEO title="Vileo" />

            <Box className={classes.root} id="vileo">
                <div ref={refVileo}>
                    <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                        spacing={2}
                        className={classes.container}
                    >
                        <Grid item xs>
                            <Box>
                                <Typography
                                    className={
                                        isVisible
                                            ? classes.text
                                            : classes.hiddenText
                                    }
                                    gutterBottom
                                >
                                    <p>
                                        {isEnglish ? 'The ' : 'Aplikacja '}
                                        <a
                                            className={classes.vileoLink}
                                            href="https://vileo.app"
                                            target="_blank"
                                        >
                                            Vileo
                                        </a>
                                        {(isEnglish && ' app') || ' '}
                                        {vileoAboutText}
                                        <a
                                            className={classes.vileoLink}
                                            href="https://vileo.app"
                                            target="_blank"
                                        >
                                            Vileo
                                        </a>
                                        {'!'}
                                    </p>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs className={classes.imgContainer}>
                            <img
                                src={mobileApp}
                                alt="Mobile App"
                                className={
                                    isVisible
                                        ? classes.img
                                        : classes.hiddenImage
                                }
                            />
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Layout>
    );
};
export default Vileo;

import { makeStyles } from '@material-ui/core/styles';

import backgroundGif from '../images/homepageGif.gif';
import background from '../images/backgroundIndex.png';

export const useStyles = makeStyles(theme => ({
    appbar: {
        backgroundColor: 'transparent',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.darkBlue,
            height: '9vh',
            minHeight: '50px',
            maxHeight: '70px',
        },
    },
    appbarBlack: {
        backgroundColor: 'rgba(0,0,0,0.85)',
    },
    title: {
        color: theme.palette.common.white,
        textDecoration: 'none',
        fontSize: '18px',
        fontWeight: 305,
        lineHeight: '21px',
        letterSpacing: '0.1em',
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(0, 14),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 10),
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            padding: theme.spacing(0, 5),
            '& .MuiIconButton-root': {
                padding: theme.spacing(0),
            },
        },
    },
    languageSwitch: {
        marginRight: '2.4vw',
        minWidth: '30px',
        maxWidth: '30px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    menuButton: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '16vh',
        padding: theme.spacing(3),
        borderBottom: '1px solid rgba(255,255,255, 0.7)',
        '@media (max-width: 300px)': {
            padding: theme.spacing(3, 1),
        },
    },
    drawer: {
        background: `url(${background}), url(${backgroundGif})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    civileo: {
        width: '16vw',
        minWidth: '100px',
        maxWidth: '180px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(0),
            height: '9vh',
        },
        transition: 'height 0.3s ease-in-out',
    },
    iconCivileo: {
        paddingTop: '6px',
        '@media (max-width: 1400px)': {
            paddingTop: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            height: '6vh',
            maxHeight: '40px',
            minHeight: '30px',
            paddingTop: theme.spacing(0),
        },
    },
}));

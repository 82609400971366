import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    '@keyframes slidein-left': {
        '0%': {
            transform: 'translateX(-7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    '@keyframes slidein-right': {
        '0%': {
            transform: 'translateX(7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    root: {
        backgroundColor: theme.palette.common.white,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(24, 16),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(10, 14),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(10, 5, 5),
        },
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
    },
    text: {
        color: theme.palette.common.black,
        fontSize: '32px',
        lineHeight: '38px',
        letterSpacing: '0.01em',
        fontWeight: 415,
        width: '90%',
        animation: '$slidein-left 0.5s ease backwards',
        [theme.breakpoints.down('lg')]: {
            fontSize: '26px',
            lineHeight: '30px',
        },
        '@media (max-width: 1400px)': {
            fontSize: '24px',
            lineHeight: '28px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '21px',
            lineHeight: '24px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '20px',
            width: '100%',
            animation: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            lineHeight: '14px',
        },
    },
    img: {
        animation: '$slidein-right 0.5s ease backwards',
        height: '100%',
        marginBottom: '0px',
        paddingTop: '4px',
        [theme.breakpoints.down('lg')]: {
            height: '80%',
        },
        [theme.breakpoints.down('sm')]: {
            animation: 'none',
        },
    },
    hiddenText: {
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        color: theme.palette.common.black,
        fontWeight: 415,
        letterSpacing: '0.01em',
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '20px',
            width: '100%',
            opacity: 1,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            lineHeight: '14px',
        },
    },
    hiddenImage: {
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        [theme.breakpoints.down('sm')]: {
            height: '80%',
            opacity: 1,
        },
        height: '100%',
        marginBottom: '0px',
        paddingTop: '4px',
    },
    vileoLink: {
        color: theme.palette.lightBlue,
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:focus': {
            textDecoration: 'none',
        },
        '&:active': {
            textDecoration: 'none',
        },
    },
}));

export const arms = [
    {
        imageGray: 'BedzinoGray',
        imageColor: 'Bedzino',
        title: `Gmina Będzino`,
    },
    {
        imageGray: 'OlesnicaGray',
        imageColor: 'Olesnica',
        title: 'Gmina Miasta Oleśnica',
    },
    {
        imageGray: 'ChoczewoGray',
        imageColor: 'Choczewo',
        title: `Gmina Miasta Choczewo`,
    },
    {
        imageGray: 'PruszczGdanskiGray',
        imageColor: 'PruszczGdanski',
        title: `Pruszcz Gdański`,
    },
    {
        imageGray: 'CzestochowaGray',
        imageColor: 'Czestochowa',
        title: 'Częstochowa',
    },
    {
        imageGray: 'KosakowoGray',
        imageColor: 'Kosakowo',
        title: `Gmina Kosakowo`,
    },
    {
        imageGray: 'OsiekGray',
        imageColor: 'Osiek',
        title: 'Gmina Osiek',
    },
    {
        imageGray: 'SopotGray',
        imageColor: 'Sopot',
        title: `Sopot`,
    },
    {
        imageGray: 'ZblewoGray',
        imageColor: 'Zblewo',
        title: 'Gmina Zblewo',
    },
    {
        imageGray: 'StezycaGray',
        imageColor: 'Stezyca',
        title: `Gmina Stężyca`,
    },
    {
        imageGray: 'PuckGray',
        imageColor: 'Puck',
        title: 'Gmina Puck',
    },
    {
        imageGray: 'MalechowoGray',
        imageColor: 'Malechowo',
        title: `Gmina Malechowo`,
    },
    {
        imageGray: 'MielnoGray',
        imageColor: 'Mielno',
        title: 'Gmina Mielno',
    },
    {
        imageGray: 'StarogardGdanskiGray',
        imageColor: 'StarogardGdanski',
        title: `Starogard Gdański`,
    },
    {
        imageGray: 'WladyslawowoGray',
        imageColor: 'Wladyslawowo',
        title: 'Władysławowo',
    },
    {
        imageGray: 'MiasteczkoSlaskieGray',
        imageColor: 'MiasteczkoSlaskie',
        title: 'Miasteczko Śląskie',
    },
]
import { makeStyles } from '@material-ui/core/styles';

import nextArrow from '../images/icons/next.svg';
import prevArrow from '../images/icons/prev.svg';
import orangePrevArrow from '../images/icons/orangePrev.svg';
import orangeNextArrow from '../images/icons/orangeNext.svg';

export const useStylesNews = makeStyles(theme => ({
    '@keyframes slidein-left': {
        '0%': {
            transform: 'translateX(-7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    '@keyframes slidein-right': {
        '0%': {
            transform: 'translateX(7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    root: {
        backgroundColor: theme.palette.common.white,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(24, 16),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(10, 14),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(5, 5, 5),
        },
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        '@media (max-width: 1000px)': {
            height: 'auto',
            marginTop: '5vh',
        },
    },
    text: {
        color: theme.palette.common.black,
        whiteSpace: 'pre-wrap',
        fontSize: '32px',
        lineHeight: '38px',
        letterSpacing: '0.01em',
        fontWeight: 415,
        marginRight: '5%',
        marginTop: '13vh',
        animation: '$slidein-left 0.5s ease backwards',
        [theme.breakpoints.down('lg')]: {
            fontSize: '21px',
            lineHeight: '30px',
        },
        '@media (max-width: 1400px)': {
            fontSize: '19px',
            lineHeight: '28px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            lineHeight: '24px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '11px',
            lineHeight: '20px',
            width: '100%',
            animation: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '11px',
            lineHeight: '14px',
        },
        '@media (max-width: 1000px)': {
            width: 'auto',
            marginRight: '0px',
            marginTop: '0px',
        },
        '@media (min-width: 1800px)': {
            fontSize: '29px',
            lineHeight: '28px',
            marginTop: '7vh',
        },
        '@media (min-width: 1900px)': {
            marginTop: '14vh',
        },
        '@media (max-height: 900px)': {
            marginTop: '0vh',
        },
        '@media (max-height: 800px)': {
            marginTop: '7vh',
            fontSize: '20px',
            lineHeight: '25px',
        },
        '@media (max-height: 650px)': {
            marginTop: '2vh',
            fontSize: '20px',
            lineHeight: '25px',
        },
        '@media (max-width: 400px) and (max-height: 600px)': {
            marginTop: '0',
            fontSize: '10px',
            lineHeight: '15px',
        },
        
    },
    img: {
        animation: '$slidein-right 0.5s ease backwards',
        height: 'auto',
        width: '100%',
        marginBottom: '0px',
        paddingTop: '4px',
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            animation: 'none',
        },
        '@media (max-width: 1000px)': {
            paddingTop: '0px',
        },
    },
    hiddenText: {
        opacity: 0,
        whiteSpace: 'pre-wrap',
        fontSize: '21px',
        lineHeight: '30px',
        transition: 'opacity 1s, transform 0.5s',
        color: theme.palette.common.black,
        fontWeight: 415,
        letterSpacing: '0.01em',
        width: '35%',
        marginRight: '5%',
        marginTop: '13vh',
        [theme.breakpoints.down('lg')]: {
            fontSize: '21px',
            lineHeight: '30px',
        },
        '@media (max-width: 1400px)': {
            fontSize: '19px',
            lineHeight: '28px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            lineHeight: '24px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '11px',
            lineHeight: '20px',
            width: '100%',
            animation: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '11px',
            lineHeight: '14px',
        },
        '@media (max-width: 1000px)': {
            width: 'auto',
            marginRight: '0px',
            marginTop: '0px',
        },
        '@media (min-width: 1800px)': {
            fontSize: '29px',
            lineHeight: '28px',
            marginTop: '7vh',
        },
        '@media (min-width: 1900px)': {
            marginTop: '14vh',
        },
        '@media (max-height: 900px)': {
            marginTop: '0vh',
        },
        '@media (max-height: 800px)': {
            marginTop: '7vh',
            fontSize: '20px',
            lineHeight: '25px',
        },
        '@media (max-height: 650px)': {
            marginTop: '2vh',
            fontSize: '20px',
            lineHeight: '25px',
        },
        '@media (max-width: 400px) and (max-height: 600px)': {
            marginTop: '0',
            fontSize: '10px',
            lineHeight: '15px',
        },
    },
    hiddenImage: {
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '80%',
            opacity: 1,
        },
        marginBottom: '0px',
        paddingTop: '4px',
        '@media (max-width: 1000px)': {
            paddingTop: '0px',
        },
    },
    next: {
        animation: '$slidein-right 0.5s ease backwards',
        backgroundImage: `url(${nextArrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        cursor: 'pointer',
        height: '72px',
        width: '27px',
        position: 'absolute !important',
        left: '4.6vh',
        '&:hover': {
            backgroundImage: `url(${orangeNextArrow})`,
        },
        [theme.breakpoints.down('lg')]: {
            height: '50px',
            width: '15px',
        },
        '@media (max-width: 600px)': {
            left: '5px',
        },
        '@media (min-width: 1000px)': {
            left: '30px',
            height: '100px',
            width: '33px',
        },
        '@media (max-width: 400px) and (max-height: 600px)': {
            bottom: '5px',
        },
    },
    prev: {
        animation: '$slidein-left 0.5s ease backwards',
        backgroundImage: `url(${prevArrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        cursor: 'pointer',
        height: '72px',
        width: '27px',
        position: 'absolute !important',
        right: '4.6vh',
        '&:hover': {
            backgroundImage: `url(${orangePrevArrow})`,
        },
        [theme.breakpoints.down('lg')]: {
            height: '50px',
            width: '15px',
        },
        '@media (max-width: 600px)': {
            right: '5px',
        },
        '@media (min-width: 1000px)': {
            right: '30px',
            height: '100px',
            width: '33px',
        },
        '@media (max-width: 400px) and (max-height: 600px)': {
            bottom: '5px',
        },
    },
    pagingDotsCSS: {
        '@media (max-width: 600px)': {
            top: '40px',
        },
    },
    contentWrapper: {
        display: "flex",
        padding: "0 20px",
        '@media (max-width: 1000px)': {
           flexDirection: "column"
        },
    },
    box: {
        display: "flex",
        alignItems: "center",
        height: "100%"
    }
}));

import React, { useState, useEffect, useRef } from 'react';
import { useStyles } from '../styles/smart-city-charts.styles';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { handleObserver } from '../utils/observer';
import {
    smartCityChartTextOneReplacedPL,
    smartCityChartTextOneReplacedEN,
    smartCityChartTextTwoReplacedEN,
    smartCityChartTextTwoReplacedPL,
    smartCityChartTextThreeReplacedEN,
    smartCityChartTextThreeReplacedPL,
} from '../utils/static';
import SingleChart from '../components/Chart/Chart';

const SmartCityCharts = ({ isEnglish }) => {
    const classes = useStyles();
    const [isVisible, setVisible] = useState(false);
    const chartOneText = isEnglish
        ? smartCityChartTextOneReplacedEN
        : smartCityChartTextOneReplacedPL;
    const chartTwoText = isEnglish
        ? smartCityChartTextTwoReplacedEN
        : smartCityChartTextTwoReplacedPL;
    const chartThreeText = isEnglish
        ? smartCityChartTextThreeReplacedEN
        : smartCityChartTextThreeReplacedPL;

    const refSmart = useRef(null);

    useEffect(() => {
        const currentRef = refSmart.current;
        handleObserver(currentRef, setVisible);
    }, []);

    return (
        <Layout
            footerTitle={isEnglish ? 'Cooperation' : 'Współpraca'}
            href="#wspolpraca"
            backgroundColor="#140091"
        >
            <SEO title="Smart City" />
            <div className={classes.root} id="smart-city">
                <div ref={refSmart}>
                    <div className={classes.wrapper}>
                        <div
                            className={
                                isVisible
                                    ? classes.columnOne
                                    : classes.columnOneHidden
                            }
                        >
                            <div className={classes.chartDescription}>
                                <p className={classes.chartDescriptionText}>
                                    {chartOneText}
                                </p>
                            </div>
                            <div className={classes.chartDescription}>
                                <p className={classes.chartDescriptionText}>
                                    {chartTwoText}
                                </p>
                            </div>
                            <div className={classes.chartDescription}>
                                <p className={classes.chartDescriptionText}>
                                    {chartThreeText}
                                </p>
                            </div>
                        </div>
                        <div
                            className={
                                isVisible
                                    ? classes.columnTwo
                                    : classes.columnTwoHidden
                            }
                        >
                            <div className={classes.legend}>
                                <span
                                    className={`${classes.legendElementOrange}`}
                                >
                                    {isEnglish ? '% detection' : '% wykrycia'}
                                </span>
                                <span
                                    className={`${classes.legendElementOrange} ${classes.legendElementWhite}`}
                                >
                                    {isEnglish
                                        ? 'proceedings found'
                                        : 'postępowania stwierdzone'}
                                </span>
                            </div>
                            <div className={classes.chartsSection}>
                                <div
                                    className={classes.chartsWithHeaderWrapper}
                                >
                                    <h5 className={classes.chartHeader}>
                                        {isEnglish
                                            ? 'GENERAL CRIME'
                                            : 'PRZESTĘPCZOŚĆ OGÓŁEM'}
                                    </h5>
                                    <div className={classes.fiveChartsWrapper}>
                                        <SingleChart
                                            countOne={11.423}
                                            countTwo={12.689}
                                            year={2015}
                                            percentage={55.9}
                                            columnOneHeight={6.63}
                                            columnTwoHeight={11.85}
                                        />
                                        <SingleChart
                                            countOne={9.907}
                                            countTwo={10.899}
                                            year={2016}
                                            percentage={57.5}
                                            columnOneHeight={5.85}
                                            columnTwoHeight={10.18}
                                        />
                                        <SingleChart
                                            countOne={9.414}
                                            countTwo={11.399}
                                            year={2017}
                                            percentage={66.6}
                                            columnOneHeight={7.08}
                                            columnTwoHeight={10.64}
                                        />
                                        <SingleChart
                                            countOne={9.608}
                                            countTwo={9.416}
                                            year={2018}
                                            percentage={65.6}
                                            columnOneHeight={5.766}
                                            columnTwoHeight={8.79}
                                        />
                                        <SingleChart
                                            countOne={9.606}
                                            countTwo={9.591}
                                            year={2019}
                                            percentage={66.7}
                                            columnOneHeight={5.98}
                                            columnTwoHeight={8.98}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={classes.chartsWithHeaderWrapper}
                                >
                                    <h5 className={classes.chartHeader}>
                                        {isEnglish
                                            ? 'VEHICLE THEFT'
                                            : 'KRADZIEŻ POJAZDÓW'}
                                    </h5>
                                    <div className={classes.fiveChartsWrapper}>
                                        <SingleChart
                                            countOne={306}
                                            countTwo={304}
                                            year={2015}
                                            percentage={14.3}
                                            columnOneHeight={1.6}
                                            columnTwoHeight={11.2}
                                        />
                                        <SingleChart
                                            countOne={341}
                                            countTwo={337}
                                            year={2016}
                                            percentage={16.4}
                                            columnOneHeight={2.05}
                                            columnTwoHeight={12.5}
                                        />
                                        <SingleChart
                                            countOne={236}
                                            countTwo={243}
                                            year={2017}
                                            percentage={17.6}
                                            columnOneHeight={1.584}
                                            columnTwoHeight={8.98}
                                        />
                                        <SingleChart
                                            countOne={155}
                                            countTwo={141}
                                            year={2018}
                                            percentage={28.3}
                                            columnOneHeight={1.491}
                                            columnTwoHeight={5.27}
                                        />
                                        <SingleChart
                                            countOne={122}
                                            countTwo={122}
                                            year={2019}
                                            percentage={50.3}
                                            columnOneHeight={2.4}
                                            columnTwoHeight={4.53}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={classes.chartsWithHeaderWrapper}
                                >
                                    <h5 className={classes.chartHeader}>
                                        {isEnglish
                                            ? 'ROBBERY CRIME'
                                            : 'PRZESTĘPCZOŚĆ ROZBÓJNICZA'}
                                    </h5>
                                    <div className={classes.fiveChartsWrapper}>
                                        <SingleChart
                                            countOne={201}
                                            countTwo={211}
                                            year={2015}
                                            percentage={55.8}
                                            columnOneHeight={7.25}
                                            columnTwoHeight={13}
                                        />
                                        <SingleChart
                                            countOne={153}
                                            countTwo={159}
                                            year={2016}
                                            percentage={48.5}
                                            columnOneHeight={4.75}
                                            columnTwoHeight={9.81}
                                        />
                                        <SingleChart
                                            countOne={115}
                                            countTwo={135}
                                            year={2017}
                                            percentage={60.3}
                                            columnOneHeight={5}
                                            columnTwoHeight={8.33}
                                        />
                                        <SingleChart
                                            countOne={123}
                                            countTwo={129}
                                            year={2018}
                                            percentage={60.5}
                                            columnOneHeight={4.8}
                                            columnTwoHeight={7.96}
                                        />
                                        <SingleChart
                                            countOne={102}
                                            countTwo={111}
                                            year={2019}
                                            percentage={63.4}
                                            columnOneHeight={4.31}
                                            columnTwoHeight={6.85}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a
                                    href="http://bip.katowice.kwp.policja.gov.pl/KWK/stan-bezpieczenstwa/294,Stan-bezpieczenstwa.html"
                                    target="_blank"
                                    className={classes.srcLink}
                                >
                                    <p>
                                        {isEnglish ? 'Source: ' : 'Źródło: '}
                                        http://bip.katowice.kwp.policja.gov.pl/KWK/stan-bezpieczenstwa/294,Stan-bezpieczenstwa.html
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default SmartCityCharts;

import { makeStyles } from '@material-ui/core/styles';

import backgroundGif from '../images/backgroundGif.gif';
import nextArrow from '../images/icons/next.svg';
import prevArrow from '../images/icons/prev.svg';
import orangePrevArrow from '../images/icons/orangePrev.svg';
import orangeNextArrow from '../images/icons/orangeNext.svg';

export const useStyles = makeStyles(theme => ({
    '@keyframes slidein-left': {
        '0%': {
            transform: 'translateX(-7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    root: {
        backgroundColor: `rgba(20, 0, 145, 0.85)`,
        backgroundSize: 'auto 100vh',
        height: '100vh',
        padding: theme.spacing(24, 16),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(10, 14),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(10),
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.darkBlue,
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(10, 5),
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    gif: {
        backgroundImage: `url(${backgroundGif})`,
        [theme.breakpoints.down('sm')]: {
            background: 'none',
        },
        height: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100vw 110vh',
        backgroundAttachment: 'fixed',
    },
    gridContainer: {
        marginBottom: '10vh',
        marginTop: '10vh',
        width: '35%',
        [theme.breakpoints.down('lg')]: {
            marginBottom: '4vh',
            marginTop: '4vh',
            width: '42%',
        },
        [theme.breakpoints.down('md')]: {
            width: '60%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            animation: 'none',
        },
        animation: '$slidein-left 0.5s ease backwards',
    },
    text: {
        fontSize: '26px',
        fontWeight: 'bold',
        lineHeight: '32px',
        letterSpacing: '0.02em',
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: {
            fontSize: '18px',
            lineHeight: '24px',
            letterSpacing: '0.01em',
            fontWeight: 'bold',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '18px',
            letterSpacing: '0.02em',
        },
    },
    slider: {
        width: '100%',

        '&:hover': {
            '& .MuiTypography-body1': {
                opacity: 1,
            },
        },
        '& .MuiTypography-body1': {
            opacity: 0,
            [theme.breakpoints.down('sm')]: {
                opacity: 1,
            },
        },
    },
    carouselWrapper: {
        outline: 'none !important',
        '&:focus': {
            outline: 'none',
        },
        '& .slider-slide': {
            outline: 'none',
            '&:focus': {
                outline: 'none',
            },
        },
    },
    hidden: {
        width: '35%',
        opacity: 0,
        transition: 'opacity 1s, transform 1.3s',
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
            width: '100%',
        },
        marginBottom: '10vh',
        marginTop: '10vh',
        [theme.breakpoints.down('lg')]: {
            marginBottom: '4vh',
            marginTop: '4vh',
            width: '42%',
        },
        [theme.breakpoints.down('md')]: {
            width: '60%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            animation: 'none',
        },
        '@media (max-width: 480px)': {
            width: '100%',
        },
    },
    next: {
        backgroundImage: `url(${nextArrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        cursor: 'pointer',
        height: '72px',
        width: '27px',
        '&:hover': {
            backgroundImage: `url(${orangeNextArrow})`,
        },
        [theme.breakpoints.down('lg')]: {
            height: '50px',
            width: '15px',
        },
    },
    prev: {
        backgroundImage: `url(${prevArrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        cursor: 'pointer',
        height: '72px',
        width: '27px',
        '&:hover': {
            backgroundImage: `url(${orangePrevArrow})`,
        },
        [theme.breakpoints.down('lg')]: {
            height: '50px',
            width: '15px',
        },
    },
    link: {
        color: theme.palette.orange,
        cursor: 'pointer',
        fontWeight: 'bold',
    },
}));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    '@keyframes slidein-left': {
        '0%': {
            transform: 'translateX(-7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    '@keyframes slidein-right': {
        '0%': {
            transform: 'translateX(7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    root: {
        backgroundColor: theme.palette.blue,
        height: '100vh',
        width: '100vw',

        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    wrapper: {
        display: 'flex',
        height: '93vh',
        alignItems: 'center',
        '@media (max-width: 768px)': {
            height: 'auto',
        },
    },
    columnOne: {
        maxWidth: '42vw',
        minWidth: '42vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: '2.4vh',
        animation: '$slidein-left 0.5s ease backwards',
        '@media (max-width: 768px)': {
            display: 'none',
            animation: 'none',
        },
    },
    columnTwo: {
        maxWidth: '58vw',
        minWidth: '58vw',
        paddingLeft: '5.2vw',
        animation: '$slidein-right 0.5s ease backwards',
        '@media (max-width: 768px)': {
            height: 'auto',
            maxWidth: '95vw',
            minWidth: '95vw',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: 0,
            margin: '0 auto',
            animation: 'none',
            marginBottom: '12.8vw',
        },
    },
    chartDescription: {
        minWidth: '25vw',
        maxWidth: '25vw',
        fontSize: 16,
        minHeight: '23.6vh',
        maxHeight: '23.6vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: '1.56vw',
    },
    chartDescriptionText: {
        margin: '0',
        marginBottom: '5vh',
    },
    legend: {
        display: 'flex',
        marginBottom: '2vw',
        justifyContent: 'flex-start',
        maxWidth: '48vw',
        '@media (max-width: 1024px)': {
            maxWidth: '50vw',
            fontSize: 14,
        },
        '@media (max-width: 768px)': {
            maxWidth: '75vw',
            minWidth: '75vw',
            flexDirection: 'row',
            margin: '12.8vw auto',
        },
        '@media (max-width: 460px)': {
            flexDirection: 'column',
        },
    },
    legendElementOrange: {
        marginRight: '60px',
        fontSize: 16,
        position: 'relative',
        paddingLeft: '62px',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 1366px)': {
            minWidth: '16vw',
        },
        '@media (max-width: 1024px)': {
            fontSize: 14,
        },
        '@media (max-width: 768px)': {
            paddingLeft: 0,
            marginLeft: 62,
            fontSize: 14,
            marginTop: '6.4vw',
        },
        '&::before': {
            content: '""',
            backgroundColor: '#FA7800',
            display: 'block',
            height: 10,
            minWidth: 49,
            maxWidth: 49,
            marginLeft: -62,
            marginRight: 13,
        },
    },
    legendElementBlue: {
        '&::before': {
            backgroundColor: 'rgba(255,255,255,0.6)',
        },
    },
    legendElementWhite: {
        '&::before': {
            backgroundColor: 'rgba(255,255,255)',
        },
    },
    chartsSection: {},
    chartsWithHeaderWrapper: {
        maxHeight: '23.6vh',
        minHeight: '23.6vh',

        marginTop: '1.56vw',
    },
    chartHeader: {
        fontSize: '2.7vh',
        margin: 0,
        marginBottom: '1.2vh',
    },
    fiveChartsWrapper: {
        display: 'flex',
        maxHeight: '19.7vh',
        minHeight: '19.7vh',
        overflow: 'hidden',
        '@media (max-width: 768px)': {
            justifyContent: 'space-between',
        },
    },
    columnOneHidden: {
        maxWidth: '42vw',
        minWidth: '42vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: '2.4vh',
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
        },
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    columnTwoHidden: {
        opacity: 0,
        transition: 'opacity 1s, transform 0.5s',
        maxWidth: '58vw',
        minWidth: '58vw',
        paddingLeft: '5.2vw',
        '@media (max-width: 768px)': {
            opacity: 1,
            animation: 'none',
            height: 'auto',
            margin: ' 0 auto',
            padding: 0,
            maxWidth: '95vw',
            minWidth: '95vw',
            marginBottom: '12.8vw',
        },
    },
    srcLink: {
        color: 'white',
        fontSize: 12,
        '@media (max-width: 768px)': {
            fontSize: 12,
        },
    },
}));

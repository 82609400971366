import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Czestochowa from '../images/arms/Czestochowa.svg';
import Bedzino from '../images/arms/GminaBedzino.svg';
import Kosakowo from '../images/arms/GminaKosakowo.svg';
import Malechowo from '../images/arms/GminaMalechowo.svg';
import Choczewo from '../images/arms/GminaMiastaChoczewo.svg';
import Mielno from '../images/arms/GminaMielno.svg';
import Osiek from '../images/arms/GminaOsiek.svg';
import Puck from '../images/arms/GminaPuck.svg';
import Stezyca from '../images/arms/GminaStezyca.svg';
import Zblewo from '../images/arms/GminaZblewo.svg';
import MiasteczkoSlaskie from '../images/arms/MiasteczkoSlaskie.svg';
import PruszczGdanski from '../images/arms/PruszczGdanski.svg';
import StarogardGdanski from '../images/arms/StarogardGdanski.svg';
import Wladyslawowo from '../images/arms/Wladyslawowo.svg';
import Sopot from '../images/arms/Sopot.svg';
import Olesnica from '../images/arms/GminaMiastaOlesnica.svg';
import CzestochowaGray from '../images/arms/GrayCzestochowa.svg';
import BedzinoGray from '../images/arms/GrayGminaBedzino.svg';
import KosakowoGray from '../images/arms/GrayGminaKosakowo.svg';
import MalechowoGray from '../images/arms/GrayGminaMalechowo.svg';
import ChoczewoGray from '../images/arms/GrayGminaMiastaChoczewo.svg';
import MielnoGray from '../images/arms/GrayGminaMielno.svg';
import OsiekGray from '../images/arms/GrayGminaOsiek.svg';
import PuckGray from '../images/arms/GrayGminaPuck.svg';
import StezycaGray from '../images/arms/GrayGminaStezyca.svg';
import ZblewoGray from '../images/arms/GrayGminaZblewo.svg';
import MiasteczkoSlaskieGray from '../images/arms/GrayMiasteczkoSlaskie.svg';
import PruszczGdanskiGray from '../images/arms/GrayPruszczGdanski.svg';
import StarogardGdanskiGray from '../images/arms/GrayStarogardGdanski.svg';
import WladyslawowoGray from '../images/arms/GrayWladyslawowo.svg';
import SopotGray from '../images/arms/GraySopot.svg';
import OlesnicaGray from '../images/arms/GrayGminaMiastaOlesnica.svg';

const styledBy = (property, mapping) => props => mapping[props[property]];

const styles = {
    root: {
        backgroundRepeat: 'no-repeat',
        height: '21vh',
        minHeight: '100px',
        maxHeight: '200px',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        transition: 'all 0.2s ease-in-out',
        backgroundImage: styledBy('imageGray', {
            BedzinoGray: `url(${BedzinoGray})`,
            OlesnicaGray: `url(${OlesnicaGray})`,
            ChoczewoGray: `url(${ChoczewoGray})`,
            PruszczGdanskiGray: `url(${PruszczGdanskiGray})`,
            CzestochowaGray: `url(${CzestochowaGray})`,
            KosakowoGray: `url(${KosakowoGray})`,
            OsiekGray: `url(${OsiekGray})`,
            SopotGray: `url(${SopotGray})`,
            ZblewoGray: `url(${ZblewoGray})`,
            StezycaGray: `url(${StezycaGray})`,
            PuckGray: `url(${PuckGray})`,
            MalechowoGray: `url(${MalechowoGray})`,
            MielnoGray: `url(${MielnoGray})`,
            StarogardGdanskiGray: `url(${StarogardGdanskiGray})`,
            WladyslawowoGray: `url(${WladyslawowoGray})`,
            MiasteczkoSlaskieGray: `url(${MiasteczkoSlaskieGray})`,
        }),
        '@media (max-width: 959px)': {
            backgroundImage: styledBy('imageColor', {
                Bedzino: `url(${Bedzino})`,
                Olesnica: `url(${Olesnica})`,
                Choczewo: `url(${Choczewo})`,
                PruszczGdanski: `url(${PruszczGdanski})`,
                Czestochowa: `url(${Czestochowa})`,
                Kosakowo: `url(${Kosakowo})`,
                Osiek: `url(${Osiek})`,
                Sopot: `url(${Sopot})`,
                Zblewo: `url(${Zblewo})`,
                Stezyca: `url(${Stezyca})`,
                Puck: `url(${Puck})`,
                Malechowo: `url(${Malechowo})`,
                Mielno: `url(${Mielno})`,
                StarogardGdanski: `url(${StarogardGdanski})`,
                Wladyslawowo: `url(${Wladyslawowo})`,
                MiasteczkoSlaskie: `url(${MiasteczkoSlaskie})`,
            }),
        },
        '&:hover': {
            backgroundImage: styledBy('imageColor', {
                Bedzino: `url(${Bedzino})`,
                Olesnica: `url(${Olesnica})`,
                Choczewo: `url(${Choczewo})`,
                PruszczGdanski: `url(${PruszczGdanski})`,
                Czestochowa: `url(${Czestochowa})`,
                Kosakowo: `url(${Kosakowo})`,
                Osiek: `url(${Osiek})`,
                Sopot: `url(${Sopot})`,
                Zblewo: `url(${Zblewo})`,
                Stezyca: `url(${Stezyca})`,
                Puck: `url(${Puck})`,
                Malechowo: `url(${Malechowo})`,
                Mielno: `url(${Mielno})`,
                StarogardGdanski: `url(${StarogardGdanski})`,
                Wladyslawowo: `url(${Wladyslawowo})`,
                MiasteczkoSlaskie: `url(${MiasteczkoSlaskie})`,
            }),
        },
    },
};

const StyledIcon = withStyles(styles)(
    ({ classes, imageGray, imageColor, children, ...other }) => (
        <Box className={classes.root} {...other}>
            {children}
        </Box>
    ),
);
export default StyledIcon;

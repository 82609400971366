import React from 'react';
import { useStyles } from './Chart.styles';

const SingleChart = props => {
    const classes = useStyles(props);
    const { countOne, countTwo, year, percentage } = props;

    return (
        <div className={classes.singleChartWrapper}>
            <div className={classes.singleChartColumnsWrapper}>
                <div className={classes.singleChartColumnWrapper}>
                    <span className={classes.singleChartColumnNumber}>
                        {countTwo}
                    </span>
                    <div className={classes.singleChartColumnOne}>
                        <span className={classes.percentage}>
                            {percentage}%
                        </span>
                    </div>
                    <div className={classes.singleChartColumnTwo}></div>
                </div>
            </div>
            <div className={classes.yearAndPercentWrapper}>
                <span className={classes.year}>{year}</span>
            </div>
        </div>
    );
};

export default SingleChart;

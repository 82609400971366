import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import MuiLink from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import { useStyles } from '../styles/navItem.styles';

const NavItem = ({ item, shouldResize, shouldChangeColor, setDrawerOn }) => {
    const { href, title, backgroundColor, color } = item;
    const active = window.location.hash === href;
    const classes = useStyles({
        backgroundColor,
        color,
        shouldResize,
        shouldChangeColor,
        active,
    });

    const handleClick = () => {
        if (typeof window !== undefined && window.innerWidth <= 960) {
            setDrawerOn(false);
        }
        navigate(href);
    };
    return (
        <MuiLink
            className={classes.title}
            underline="none"
            onClick={handleClick}
        >
            {href === '#civileo' || href === '#news' ? (
                <Box className={classes.civileo}>{title}</Box>
            ) : (
                <Box className={classes.item}>{title}</Box>
            )}
        </MuiLink>
    );
};
export default NavItem;

NavItem.propTypes = {
    item: PropTypes.object.isRequired,
    shouldResize: PropTypes.bool.isRequired,
    shouldChangeColor: PropTypes.bool,
};
